import router from '@/router'
import { getPatientById, updatePatientById } from '@/api'
import lodash from 'lodash'

export default {
  namespaced: true,
  state: {
    patient: {
      _id: null
    },
    newPatient: {
      _id: null
    },
    saving: false,
    role: ''
  },
  getters: {
  },
  mutations: {
    UPDATE_PATIENT(state, val) {
      state.patient = val
    },
    UPDATE_SAVING(state, val) {
      state.saving = val
    },
    UPDATE_ROLE(state, val) {
      state.role = val
    }
  },
  actions: {
    getPatientById({ commit }) {
      return new Promise((resolve, reject) => {
        getPatientById(router.currentRoute.params.id)
          .then(res => {
            commit('UPDATE_PATIENT', res.data.patient)
            resolve()
          })
          .catch(err => {
            reject()
          })
      })
    },
    updatePatientById({ commit, state }, patientInfo) {
      let changes = []
      Object.keys(patientInfo).forEach(key => {
        let v1 = patientInfo[key], v2 = state.patient[key]
        if (v1 != v2) {
          changes.push(key)
        }
      })

      if (!changes.length) {
        return new Promise((resolve, reject) => {
          resolve()
        })
      }

      commit('UPDATE_SAVING', true)

      return new Promise((resolve, reject) => {
        updatePatientById(patientInfo._id, lodash.pick(patientInfo, changes))
          .then(res => {
            commit('UPDATE_PATIENT', res.data.patient)
            setTimeout(() => {
              commit('UPDATE_SAVING', false)
            }, 1000)
            resolve()
          })
          .catch(err => {
            reject()
          })
      })
    },
    checkRole({ commit }, hospital_id) {
      let roleData = JSON.parse(localStorage.getItem('roleData'))
      let userData = JSON.parse(localStorage.getItem('userData'))

      if (userData.is_admin) {
        commit('UPDATE_ROLE', 'admin')
        return
      }

      let roles = roleData.filter(d => d.hospital_id == hospital_id)
      let role = roles.reduce((role, d) => {
        if (!role) {
          return d.role
        }
        if (d.role == 'exporter') {
          return role
        }
        if (d.role == 'editor') {
          return 'editor'
        }
        return role
      }, role)

      if (!role) {
        commit('UPDATE_ROLE', '')
        return
      }

      commit('UPDATE_ROLE', role)
    }
  },
}
