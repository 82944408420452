require("dotenv").config({ path: "../.env" });

const config = {
    BASE_URL: process.env.VUE_APP_BACKEND_BASE_URL,
    // BACKEND_BASE_URL: process.env.VUE_APP_BACKEND_BASE_URL,
    TIMER_OPTION: {
        time: 700, autostart: true, repeat: true
    }
}

export default config