// import ability from './ability'

// export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
export const canNavigate = (to, isLoggedIn, user) => {
    if (to.name.indexOf('admin-') == 0 && user && !user.is_admin) {
        return false
    }

    if (isLoggedIn) {
        return true
    }

    if (to.name.indexOf('auth-') == 0) {
        return true
    }

    return false
}

export const _ = undefined
